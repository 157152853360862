/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Tags from '../components/Tags'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import Time from '../components/Time'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { DiscussionEmbed } from 'disqus-react'
import Share from '../components/Share'
import BlogNav from '../components/BlogNav'

export const BlogPostTemplate = ({
    date,
    content,
    contentComponent,
    description,
    tags,
    title,
    langKey,
    image,
    imageAlt,
    author,
    authorImage,
    slug,
    url,
    twitterHandle,
}) => {
    const PostContent = contentComponent || Content
    const disqusConfig = {
        shortname: 'georgian-wine',
        config: { identifier: slug, title },
    }
    const socialConfig = {
        twitterHandle,
        config: {
            url: `${url}${slug}`,
            title,
            description,
        },
    }

    return (
        <section className="is-blog-section">
            <div className="container content">
                <BlogNav />
                <div className="columns">
                    <div className="column is-offset-2 is-8">
                        <div className="is-image-title-container">
                            <Img
                                className="has-image-filter"
                                alt={imageAlt}
                                fluid={image}
                            />
                            <div className="is-title-absolute-centered">
                                <h1 className=" has-text-white">{title}</h1>
                                <div className="has-text-white has-text-weight-medium">
                                    <Time
                                        pubdate
                                        langKey={langKey}
                                        date={date}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="has-margin-top">
                            <div className="level is-mobile">
                                <div className="level-left">
                                    <p>{description}</p>
                                </div>
                            </div>
                            <div className="level is-mobile">
                                <div className="level-left">
                                    <figure className="image is-32x32 is-margin-auto-zero">
                                        <Img
                                            className="is-image-radius-circle"
                                            fluid={authorImage}
                                        />
                                    </figure>
                                    <p className="is-size-7 has-text-primary has-padding-left-one has-text-weight-bold">
                                        {author}
                                    </p>
                                </div>
                                <div className="level-right">
                                    <div className="is-size-7 has-text-primary has-text-weight-bold">
                                        <Share
                                            socialConfig={socialConfig}
                                            tags={tags}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PostContent
                            className="has-margin-top"
                            content={content}
                        />
                        <div className="level is-mobile has-margin-top">
                            <div className="level-left">
                                <Tags tags={tags} langKey={langKey} />
                            </div>
                            <div className="level-right">
                                <div className="is-size-7 has-text-primary has-text-weight-bold">
                                    <Share
                                        socialConfig={socialConfig}
                                        tags={tags}
                                    />
                                </div>
                            </div>
                        </div>

                        <DiscussionEmbed {...disqusConfig} />
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
    location: PropTypes.string,
    tags: PropTypes.array,
    langKey: PropTypes.string,
    image: PropTypes.object,
    imageAlt: PropTypes.string,
    author: PropTypes.string,
    authorLinkedIn: PropTypes.string,
    authorPosition: PropTypes.string,
    date: PropTypes.string,
    authorQuote: PropTypes.string,
    slug: PropTypes.string,
    timeToRead: PropTypes.number,
    data: PropTypes.object,
    url: PropTypes.string,
    twitterHandle: PropTypes.string,
}

const BlogPost = ({ data, location }) => {
    const { markdownRemark: post } = data
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const langKey = post.frontmatter.lang
    const image = post.frontmatter.image.childImageSharp.fluid
    const meta = data.site.siteMetadata
    return (
        <Layout
            className="container"
            data={data}
            jsonData={jsonData}
            location={location}
        >
            <SEO frontmatter={post.frontmatter} postImage={image} isBlogPost />
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                image={image}
                author={post.frontmatter.author}
                timeToRead={post.timeToRead}
                authorImage={post.frontmatter.authorImage.childImageSharp.fluid}
                authorPosition={post.frontmatter.authorPosition}
                authorLinkedIn={post.frontmatter.authorLinkedIn}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                slug={post.frontmatter.slug}
                url={meta.siteUrl}
                twitterHandle={meta.social.twitter}
                langKey={langKey}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    author: PropTypes.string,
    imageAlt: PropTypes.string,
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        site: PropTypes.object,
        allArticlesJson: PropTypes.object,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        site {
            siteMetadata {
                social {
                    twitter
                }
                siteUrl
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            timeToRead
            frontmatter {
                slug
                id
                title
                imageAlt
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                description
                date
                tags
                lang
                author
                authorLinkedIn
                authorPosition
                authorImage {
                    childImageSharp {
                        fluid(maxWidth: 128) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`
