import React from 'react'
import PropTypes from 'prop-types'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
} from 'react-share'

import {
    FaLinkedin,
    FaFacebookSquare,
    FaTwitterSquare,
    FaRedditSquare,
    FaWhatsappSquare,
} from 'react-icons/fa'

const Share = ({ socialConfig, tags }) => (
    <div className="is-share-centered">
        <FacebookShareButton
            url={socialConfig.config.url}
            quote={socialConfig.config.title}
            hashtag={tags}
            className="soc-icon fb"
        >
            <FaFacebookSquare className="is-facebook-icon" size="2rem" />
        </FacebookShareButton>
        <TwitterShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            via={socialConfig.twitterHandle.split('@').join('')}
            hashtags={tags}
            className="soc-icon tw"
        >
            <FaTwitterSquare className="is-twitter-icon" size="2rem" />
        </TwitterShareButton>
        <LinkedinShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            summary={socialConfig.config.description}
            className="soc-icon lin"
        >
            <FaLinkedin className="is-linkedin-icon" size="2rem" />
        </LinkedinShareButton>
        <RedditShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            className="soc-icon red"
        >
            <FaRedditSquare className="is-reddit-icon" size="2rem" />
        </RedditShareButton>
        <WhatsappShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            className="soc-icon wh"
        >
            <FaWhatsappSquare className="is-whatsapp-icon" size="2rem" />
        </WhatsappShareButton>
    </div>
)

Share.propTypes = {
    socialConfig: PropTypes.shape({
        twitterHandle: PropTypes.string.isRequired,
        config: PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
    tags: [],
}

export default Share
